import React from 'react';
import { useSetState } from 'react-use';
import store from "../../../../store";

// FUNCTIONS
import { 
    fetchUsageHistory,
} from "../../../../reducers/UserPackageReducers";
import { toastNotification } from '../../../../modules/ToastNotification';

// COMPONENTS
import NcloudPagination from "../../../../components/NcloudPagination";
import Loading from "../../../../components/Loading"
import NoData from '../../../../components/NoData';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

// STYLES

// ASSETS


function UsageHistoryOld(props) {
    const initialState = {
        isLoading : false,

        historyList: [],
        activePage: 1,
        offset: 0,
        // limit: 5,
        limit: 10,
        total: 0,
        
        unit: "",
    };

    const {
        id_user_package=null,
        updateUnit=()=>{},
        // source="",
        // status=""
    } = props;

	const { id_token } = useSelector(state => state.UserManagementReducers);

    const [state, setState] = useSetState({...initialState});

    useEffect(() => {
        if ( id_user_package === null ) return;

        getUsageHistoryData();
    }, [id_user_package, state.offset, state.limit])

    const getUsageHistoryData = () => {
        let { offset, limit } = state;

        setState({
            isLoading: true
        })

        // fetch usage history
        store.dispatch(fetchUsageHistory(id_token, id_user_package, offset, limit))
            .then((res) => {
                setState({
                    isLoading: false,
                    total: res.total,
                    historyList: res.history,
                    unit: res.unit
                })

                updateUnit(res.unit);
                
            })
            .catch((err) => {
                setState({
                    isLoading: false
                })

                toastNotification("error", "Gagal memuat data penggunaan", err.toString())
            });
    } 

    const handlePageChange = (pageNumber) => {
        setState({
            historyList: [],
            activePage: pageNumber,
            offset: (pageNumber-1)*state.limit,
        })
    }
    
    const { isLoading, historyList, total, unit } = state;

    return (
        <div className="usage-history-container">                                
            <h4>Riwayat Penggunaan</h4>
            <div className="usage-history-list-head">
                    <div className="title">Tanggal</div>
                    {/* <div className="title">Berkas</div> */}
                    <div className="title">Total {unit}</div>
                    {/* <div className="title">Status</div> */}
            </div>
            <div className="usage-history-wrapper">
                { isLoading ?
                    <Loading />
                    :
                    ( historyList.length === 0 ?
                        <NoData /> :
                        ( historyList.map((usage_history) => {
                            // console.log(usage_history)
                            return (
                                <div key={"usage-history-"+usage_history.id}
                                    className="usage-history-list"
                                >
                                    <div className="content">{usage_history.date}</div>
                                    {/* <div className="content">{source}</div> */}
                                    <div className="content">{usage_history.quantity}</div>
                                    {/* <div className={status === "Berhasil" ? "status success" : "status failed"}>● {status}</div> */}
                                </div>
                            )
                        }) )
                    )
                } 
            </div>
            <div className="usage-history-bottom">
                <NcloudPagination
                    activePage={state.activePage}
                    itemPerPage={state.limit}
                    totalItems={total}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default UsageHistoryOld;
