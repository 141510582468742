import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './PricePackage.scss';

const availableFeatures = [
    "Audio Transkrip",
    "Audio Translasi",
    "Live Transkrip",
    "Code-Mixing",
    "Diarisasi",
    "Registrasi Pembicara",
    "Identifikasi Pembicara",
    "Summary & Action Plan",
    "Transkripsi YouTube",
    "Word Cloud",
    "Download Audio",
    "Unduh Transkrip (PDF, WORD, SRT, dll)"
];

const comingSoonFeatures = ["Zoom", "Gmeet", "Microsoft Teams"];

const Features = () => {
    return (
        <div className="features-container bg-white">
            <div className="features-card">
                {/* Judul */}
                <h2 className="features-title">
                    Bebas Akses Semua Fitur Unggulan
                </h2>

                {/* List Fitur */}
                <div className="features-grid">
                    {availableFeatures.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="feature-icon"
                            />
                            <span className="feature-text">{feature}</span>
                        </div>
                    ))}
                </div>

                <hr className="divider" />

                {/* Coming Soon */}
                <div className="coming-soon">
                    <h3 className="coming-soon-title">Coming Soon:</h3>
                    <ul className="coming-soon-list">
                        {comingSoonFeatures.map((feature, index) => (
                            <li key={index} className="coming-soon-item">
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Features;
